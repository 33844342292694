<template>
  <ProfileComponent />
</template>

<script>
import ProfileComponent from '../components/ProfileComponent.vue'

export default {
  name: 'Profile',
  components: {
    ProfileComponent,
  },
}
</script>
