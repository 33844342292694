<template>
  <div class="py-4">
    <div class="border-4 border-dashed border-gray-200 rounded-lg ">
      <form class="p-4" @submit.prevent="saveName">
        <div class="w-full sm:col-span-3 mr-3">
          <label
            for="business_code"
            class="block text-sm font-medium text-gray-700"
          >
            Business Code</label
          >
          <input
            type="text"
            id="business_code"
            disabled
            v-model="user.business_code"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
          />
        </div>
        <div class="w-full mt-4 sm:col-span-3 mr-3">
          <label
            for="business_code"
            class="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            disabled
            v-model="user.username"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
          />
        </div>
        <div class="mt-4 sm:col-span-3 ">
          <label
            for="business_code"
            class="block text-sm font-medium text-gray-700"
          >
            Display Name
          </label>
          <input
            type="text"
            id="display_name"
            :value="displayName"
            autocomplete="off"
            @input="nameChange($event.target.value)"
            class="inline-block mt-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md py-2 pl-2"
            :class="nameHasChanges ? 'input-custom-width mr-2' : 'w-full'"
          />
          <button
            v-if="nameHasChanges"
            type="submit"
            :disabled="isSubmitted.name"
            :class="isSubmitted.name ? 'opacity-75' : ''"
            class="bg-gray-700 p-2 text-white w-1/4"
          >
            {{ isSubmitted.name ? "Saving Name..." : "Save Name" }}
          </button>
        </div>
      </form>
      <div class="p-4">
        <div class="tab__header">
          <button
            href="#"
            class="tab__link px-4 py-2 w-full bg-gray-700 hover:bg-blue-darker no-underline text-white flex justify-between"
            @click.prevent="active = !active"
          >
            <span> Change Password </span>
            <span class="down-Arrow" v-show="!active">&#9660;</span>
            <span class="up-Arrow" v-show="active">&#9650;</span>
          </button>
        </div>
        <div class="flex flex-col" v-show="active">
          <form class="py-4" @submit.prevent="changePassword">
            <div class="w-full mt-4 sm:col-span-3 mr-3">
              <label
                for="password_old_label"
                class="block text-sm font-medium text-gray-700"
              >
                Old Password
              </label>
              <input
                type="password"
                required
                id="password_old"
                v-model="password.old"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 pl-1"
              />
            </div>
            <div class="w-full mt-4 sm:col-span-3 mr-3">
              <label
                for="password_new"
                class="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                type="password"
                id="password_new"
                required
                v-model="password.new"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 pl-1"
              />
            </div>
            <div class="w-full mt-4 sm:col-span-3 mr-3">
              <label
                for="password_confirm"
                class="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <input
                type="password"
                required
                id="password_confirm"
                v-model="password.confirm"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 pl-1"
              />
            </div>

            <div role="alert" class="my-3" v-if="errorMsg != ''">
              <div
                class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>

            <div role="alert" class="my-3" v-if="successMsg != ''">
              <div
                class="border border-green-400 rounded bg-green-100 text-sm px-4 py-3 text-green-700"
              >
                <p>{{ successMsg }}.</p>
              </div>
            </div>

            <button
              type="submit"
              :disabled="isSubmitted.password"
              :class="isSubmitted.password ? 'opacity-75' : ''"
              class="bg-gray-700 text-white w-full py-2 mt-4"
            >
              {{
                isSubmitted.password ? "Saving Password..." : "Save Password"
              }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");

export default {
  name: "ProfileComponent",
  data: () => ({
    user: {},
    password: {
      new: "",
      confirm: "",
      old: "",
    },
    active: false,
    errorMsg: "",
    isSubmitted: {
      password: false,
      name: false,
    },
    successMsg: "",
    nameHasChanges: false,
    displayName: "",
  }),
  mounted() {
    this.getUser();
  },
  methods: {
    nameChange(val) {
      this.nameHasChanges =
        val === this.user.display_name || val.trim().length == 0 ? false : true;
      this.displayName = val;
    },
    getUser() {
      firebase.db
        .collection("users")
        .doc(firebase.auth.currentUser.uid)
        .onSnapshot((doc) => {
          this.user = {
            uid: doc.id,
            ...doc.data(),
          };
          this.displayName = doc.data().display_name;
        });
    },
    async changePassword() {
      this.errorMsg = "";
      this.successMsg = "";
      if (this.password.confirm !== this.password.new) {
        this.errorMsg = "Password Mismatch";
        return false;
      }
      const user = firebase.auth.currentUser;
      this.isSubmitted.password = true;
      try {
        const authenticatedUser = await firebase.auth.signInWithEmailAndPassword(
          user.email,
          this.password.old
        );

        if (authenticatedUser) {
          user.updatePassword(this.password.new).then(() => {
            this.successMsg = "Password successfully changed";
            this.isSubmitted.password = false;
          });

          this.password.confirm = "";
          this.password.old = "";
          this.password.new = "";
        }
      } catch (e) {
        this.errorMsg = e.message;
        console.info("", e);
        this.isSubmitted.password = false;
      }
    },
    async saveName() {
      const user = firebase.auth.currentUser;
      this.isSubmitted.name = true;
      try {
        await firebase.db
          .collection("users")
          .doc(user.uid)
          .update({
            display_name: this.displayName,
          });
        await user.updateProfile({
          displayName: this.displayName,
        });
        this.isSubmitted.name = false;
        this.nameHasChanges = false;
      } catch (e) {
        this.isSubmitted.name = false;
      }
    },
  },
};
</script>
<style lang="css" scoped>
.button {
  width: 49%;
}
.input-custom-width {
  width: calc(100% - 27%);
}
</style>
