import { render } from "./Profile.vue?vue&type=template&id=ced23842"
import script from "./Profile.vue?vue&type=script&lang=js"
export * from "./Profile.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ced23842"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ced23842', script)) {
    api.reload('ced23842', script)
  }
  
  module.hot.accept("./Profile.vue?vue&type=template&id=ced23842", () => {
    api.rerender('ced23842', render)
  })

}

script.__file = "src/views/Profile.vue"

export default script