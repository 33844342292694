import { render } from "./ProfileComponent.vue?vue&type=template&id=4d8cbf84&scoped=true"
import script from "./ProfileComponent.vue?vue&type=script&lang=js"
export * from "./ProfileComponent.vue?vue&type=script&lang=js"

import "./ProfileComponent.vue?vue&type=style&index=0&id=4d8cbf84&lang=css&scoped=true"
script.render = render
script.__scopeId = "data-v-4d8cbf84"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4d8cbf84"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4d8cbf84', script)) {
    api.reload('4d8cbf84', script)
  }
  
  module.hot.accept("./ProfileComponent.vue?vue&type=template&id=4d8cbf84&scoped=true", () => {
    api.rerender('4d8cbf84', render)
  })

}

script.__file = "src/components/ProfileComponent.vue"

export default script